import Vue from 'vue'
import VueRouter from 'vue-router'

import Public from '@/components/Layout/Public'
import Auth from '@/components/Layout/Auth'
import Account from '@/components/Layout/Account'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: Public,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/HomePage.vue'),
      },
      {
        path: '/shop',
        name: 'shop',
        component: () => import('../views/Shop.vue'),
      },
      {
        path: '/show/:id?',
        name: 'show',
        component: () => import('../views/Show.vue'),
      },
      {
        path: '/account',
        redirect: 'account',
        component: Account,
        children: [
          {
            path: '/account',
            name: 'account',
            component: () => import('../views/Account.vue'),
            children: [
              {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('@/components/Account/Dashboard'),
              },
              {
                path: '/transaction',
                name: 'transaction',
                component: () => import('@/components/Account/Transaction'),
              },
            ],
          },
          {
            path: '/wishlist',
            name: 'wishlist',
            component: () => import('../views/Wishlist.vue'),
          },
          {
            path: '/library',
            name: 'library',
            component: () => import('../views/Library.vue'),
          },

          {
            path: '/read/:id',
            name: 'read',
            component: () => import('../views/Read.vue'),
          },
        ], meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/login',
    redirect: 'login',
    component: Auth,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/components/Auth/Login'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/components/Auth/Register.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
