<template>
  <v-app>
    <!-- <breadcrumbs /> -->
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="2">
          <v-tabs vertical class="ver-tabs">
            <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
              item.text
            }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="10"><router-view /></v-col>
      </v-row>
    </v-container>

    <div class="text-center my-10">
      <Adsense
        data-ad-client="ca-pub-3076489163946110"
        data-ad-slot="6109811926"
      >
      </Adsense>
    </div>
  </v-app>
</template>

<script>
// import Breadcrumbs from "@/components/Breadcrumbs.vue";
export default {
  name: "App",
  components: {
    // Breadcrumbs,
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: "Dashboard", to: "/dashboard" },
      // { text: "Wishlist", to:'/wishlist'},
      { text: "My Transaction", to: "/transaction" },
      { text: "My Library", to: "/library" },
      { text: "Sign Out", to: "/" },
      //   { text: "Addresses", icon: "mdi-flag" },
    ],
  }),
};
</script>
<style>
div.ver-tabs [role="tab"] {
  justify-content: flex-start;
}
</style>
