<template>
  <v-app class="main-auth-div fill-height">
    <v-row class="fill-height" justify="center" align="center">

      <!-- <v-col md="5" class="hidden-sm-and-down">
        <v-img src="@/assets/auth-bg.png"></v-img>
      </v-col> -->
      <v-col cols="11" md="4">
        <v-card class="mx-auto my-12">
          <div class="d-flex pa-5">
            <a href="/" class="mx-auto">
              <v-img
                alt="QRCBN Logo"
                class="shrink"
                contain
                src="@/assets/qrcbn-logo-blue-full.png"
                transition="scale-transition"
                width="200"
              />
            </a>
          </div>
          <v-tabs centered fixed-tabs>
            <v-tab to="/login"><span class="text-h6">SIGN IN</span></v-tab>
            <v-tab to="/register" exact><span class="text-h6">Register</span></v-tab>
          </v-tabs>

          <v-divider />

          <router-view />
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    //
  }),
};
</script>
<style>
/* .main-auth-div {
  background: rgb(25, 171, 144) !important;
  background: linear-gradient(
    180deg,
    rgba(25, 171, 144, 1) 0%,
    rgba(148, 201, 64, 1) 100%
  ) !important;
} */
</style>
