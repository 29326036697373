<template>
  <div>
    <v-system-bar dark height="50" color="primary">
      <v-container class="d-flex align-center top-navbar-item">
        <a href="mailto:info@qrcbn.com" target="_blank"
          ><v-icon>mdi-email-outline</v-icon> EMAIL : info@qrcbn.com</a
        >
        <v-spacer></v-spacer>

        <a href="/login" class="mr-3"
          ><v-icon>mdi-account-outline</v-icon> LOGIN</a
        >

        <div class="toggle-button-cover">
          <div class="button-cover">
            <div class="button r" id="button-3">
              <input type="checkbox" class="checkbox" />
              <div class="knobs"></div>
              <div class="layer"></div>
            </div>
          </div>
        </div>
      </v-container>
    </v-system-bar>
    <v-app-bar
      color="primary"
      elevation="0"
      class="btm-nav"
      id="nav-bar"
      transition="scale-transition"
      clipped-right
    >
      <v-container class="d-flex align-center">
        <a href="/" class="mr-2">
        <v-img
          alt="Vuetify Logo"
          class="shrink"
          contain
          src="@/assets/qrcbn-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
        </a>

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/qrcbn-name-dark.png"
          width="100"
        />

        <v-spacer></v-spacer>

        <v-text-field
          v-model="keyword"
          label="Search"
          solo
          hide-details="auto"
          :append-icon="'mdi-magnify'"
          @click:append="search"
        ></v-text-field>

        <v-spacer></v-spacer>

        <a href="/dashboard" class="text-center nav-icon-menu-item mx-2">
          <v-btn icon color="#fff" class="nav-bar-btn">
            <v-icon large dark class="nav-bar-icon">mdi-account-outline</v-icon>
          </v-btn>
          <div class="nav-bar-icon-text">Account</div>
        </a>

        <a href="/library" class="text-center nav-icon-menu-item mx-2">
          <v-btn icon color="#fff" class="nav-bar-btn">
            <v-icon large dark class="nav-bar-icon">mdi-library-outline</v-icon>
          </v-btn>
          <div class="nav-bar-icon-text">Library</div>
        </a>

        <!-- <a
          href="/library"
          class="text-center nav-icon-menu-item mx-2"
        >
          <v-badge color="amber darken-2" content="6" overlap bottom>
            <v-btn icon color="#fff" class="nav-bar-btn">
              <v-icon large dark class="nav-bar-icon">mdi-library-outline</v-icon>
            </v-btn>
          </v-badge>
          <div class="nav-bar-icon-text">Library</div>
        </a> -->

        <!-- <a href="/wishlist" class="text-center nav-icon-menu-item mx-2">
          <v-badge color="amber darken-2" content="6" overlap bottom>
            <v-btn icon color="#fff" class="nav-bar-btn">
              <v-icon large dark class="nav-bar-icon">mdi-heart-outline</v-icon>
            </v-btn>
          </v-badge>
          <div class="nav-bar-icon-text">Wishlist</div>
        </a> -->
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="side_cart"
      fixed
      temporary
      right
      width="400"
      height="100vh"
      clipped
      class="side-cart"
    >
      <div class="d-flex">
        <v-btn icon @click.stop="side_cart = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <div class="cart-title ml-auto mr-auto mt-2">
          You have <strong>(1 item)</strong> in your cart
        </div>
      </div>

      <v-divider></v-divider>

      <div class="cart-item-area pa-5">
        <div class="cart-item py-3" v-for="i in 8" :key="i">
          <v-row align="center" justify="center" class="mb-2">
            <v-col cols="2">
              <div class="cart-item-img">
                <v-img
                  alt="Vuetify Logo"
                  class="shrink mr-2"
                  contain
                  src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
                  transition="scale-transition"
                  width="40"
                />
              </div>
            </v-col>
            <v-col cols="9">
              <div class="cart-item-details">
                <div class="cart-item-name">
                  <strong>Product Name {{i}}</strong>
                </div>
                <div class="cart-item-price text-subtitle-2">Rp. 70.000</div>
              </div>
            </v-col>

            <v-col cols="1">
              <div class="cart-item-action">
                <v-btn icon color="error" x-small>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>
      </div>

      <div class="side-cart-actions px-5 py-2">
        <v-row>
          <v-col cols="6">
            <v-btn outlined color="primary" class="py-5" block to="/cart">Your Cart</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" class="py-5" block to="checkout">Checkout</v-btn></v-col
          >
        </v-row>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "NavigationBar",
  components: {},
  data: () => ({
    keyword: "",
    lang: true,
    side_cart: false,
  }),
  methods: {
    search() {
      this.$router.push({
        name: "search",
        params: {
          keyword: this.keyword,
        },
      });
    },
    handleScroll(event) {
      let header = document.querySelector("#nav-bar");
      if (window.scrollY > 30 && !header.className.includes("fixed")) {
        header.classList.add("fixed");
      } else if (window.scrollY < 30) {
        header.classList.remove("fixed");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style>
.nav-bar-icon-text {
  font-size: 0.7rem;
  color: #fff;
}
.nav-bar-btn:hover,
.nav-bar-icon-text:hover {
  color: #ffd900 !important;
}
.top-navbar-item,
.top-navbar-item a {
  color: #fff !important;
  text-decoration: none;
}
.top-navbar-item a:hover {
  color: #ffa000 !important;
}
.fixed {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
#nav-bar {
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* Switch */
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 55px;
  height: 125px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  border-radius: 4px;
}

.button-cover:before {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 47px;
  height: 28px;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

#button-3 .knobs:before {
  content: "ID";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 0;
  padding: 11px 0px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-3 .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

#button-3 .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

#button-3 .checkbox:checked + .knobs:before {
  content: "EN";
  left: 22px;
  background-color: #f44336;
}

#button-3 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

/* cart */
.side-cart {
  z-index: 9999 !important;
}
.cart-item-area {
  height: 85vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.side-cart-actions {
  position: fixed;
  bottom: 0;
}
</style>
