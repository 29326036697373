import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var token = localStorage.getItem('access_token')
    if (!token) {
      next({
        path: '/login',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter, {
  symbol: 'Rp.',
  thousandsSeparator: ',',
  fractionCount: 0,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
  avoidEmptyDecimals: undefined,
})

import axios from 'axios'
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/'
axios.defaults.baseURL = 'https://my.qrcbn.com/api/'

var AUTH_TOKEN = localStorage.getItem('access_token')
if (AUTH_TOKEN) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN
}
import '@fortawesome/fontawesome-free/css/all.css'

// Adsense
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
