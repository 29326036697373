<template>
  <div class="footer">
    <v-container>
      <v-row>
        <v-col cols="12" md="7">
          <v-row>
            <v-col cols="12" md="4">
              <div class="footer-content">
                <div class="font-weight-black text-subtitle-1">QRCBN</div>
                <div class="footer-links mt-3">
                  <ul class="footer-links-item">
                    <li><a href="https://www.qrcbn.com">Tentang QRCBN</a></li>
                    <!-- <li><a href="#">Kebijakan Privasi</a></li> -->
                  </ul>
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="footer-content">
                <div class="font-weight-black text-subtitle-1">Penerbit</div>
                <div class="footer-links mt-3">
                  <ul class="footer-links-item">
                    <li><a href="https://www.qrcbn.com/register">Bergabung Menjadi Penerbit</a></li>
                    <li><a href="https://www.qrcbn.com/publish">Mulai Terbitkan Buku</a></li>
                    <li><a href="https://www.qrcbn.com/library">Jual Buku</a></li>
                  </ul>
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="4">
              <div class="footer-content">
                <div class="font-weight-black text-subtitle-1">Pembeli</div>
                <div class="footer-links mt-3">
                  <ul class="footer-links-item">
                    <li><a href="https://www.market.qrcbn.com/register">Buat Akun</a></li>
                    <li><a href="https://www.qrcbn.com/shop">Belanja Buku</a></li>
                    <!-- <li><a href="#">Sistem Pembayaran</a></li> -->
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="5">
          <div class="text-overline font-weight-black">Got Question? Message us</div>
          <div class="text-h4 mb-3 font-weigt-bold grey--text">info@qrcbn.com</div>
          &copy; {{ new Date().getFullYear() }} — <strong>QRCBN.com</strong>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style>
.footer-links-item {
  padding-left: 0 !important;
}
.footer-links-item li {
  list-style: none;
  line-height: 1.6em;
}
.footer-links-item li a {
  text-decoration: none;
  color: #616161;
  font-size: 0.9em;
}

.footer-links-item li a:hover {
  color: #2f4787;
}
</style>
