<template>
  <v-app>
    <NavigationBar />
    <v-main>
      <router-view />
    </v-main>
    <v-divider />
    <div class="text-center my-10">
      <Adsense
        data-ad-client="ca-pub-3076489163946110"
        data-ad-slot="6109811926"
      >
      </Adsense>
    </div>
    <FooterBar />
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterBar from "@/components/FooterBar.vue";
export default {
  name: "App",
  components: {
    NavigationBar,
    FooterBar,
  },
  data: () => ({
    //
  }),
};
</script>
